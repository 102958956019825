import React, { useContext } from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout, { LanguagePrefixContext } from "../components/Layout"
import Button from "../components/Button"
import { FormattedMessage } from "react-intl"

import "../components/Layout.css"
import "../components/BlogPost.css"

const PostTemplate = ({
  content,
  title,
  mainDescription,
  listImage,
  mainImage,
  author,
  authorThumb,
  publishedAt,
  readingDuration,
  helmet,
}) => {
  const containerClassNames = "container mx-auto px-6 lg:px-32"
  const langPrefix = useContext(LanguagePrefixContext)

  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK */}
      <div className="blog-header-bg mx-auto px-6 lg:px-32">
        <img
          src={listImage.childImageSharp.fixed.src}
          alt=""
          className="md:w-1/2 mx-auto"
        />
      </div>

      <div className={containerClassNames}>
        <h1 className="mt-10 font-semibold text-3xl md:text-5xl tracking-wide text-gray-900 leading-none lg:pr-48">
          {title}
        </h1>

        <div className="author flex mt-10">
          <div className="mr-5">
            <img
              src={authorThumb.childImageSharp.fixed.src}
              alt={author}
              className="w-20 rounded-full"
            />
          </div>
          <div className="flex flex-col self-center">
            <div>{author}</div>
            <div className="text-gray-400">
              {publishedAt} | {readingDuration}
            </div>
          </div>
        </div>
      </div>

      {/* ABOUT BLOCK */}
      <div className={`blog-post-body ${containerClassNames}`}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>

      {/* CALL TO ACTION BLOCK */}
      <div
        className={`call-to-action ${containerClassNames} pb-20 text-center`}
      >
        <Button to={`${langPrefix}contacts`} outlined className="mt-12">
          <FormattedMessage id="global.call-us" />
        </Button>
      </div>
    </div>
  )
}

const Post = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <PostTemplate
        helmet={
          <Helmet>
            <title>{post.frontmatter.title}</title>
            <meta
              name="description"
              content={post.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={post.html}
        {...post.frontmatter}
      />
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query PostItem($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        author
        publishedAt
        readingDuration
        mainDescription
        authorThumb {
          childImageSharp {
            fixed(width: 200, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        mainImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
